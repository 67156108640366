"use client";

import { useEffect, useRef } from "react";
import { useJitsu } from "@jitsu/jitsu-react";
import startCase from "lodash/startCase";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";

import { useAnalytics } from "context/AnalyticsContext";

const IS_LOCAL_DEV = !process.env.NEXT_PUBLIC_VERCEL_URL;
const LOCAL_URL = process.env.NEXT_PUBLIC_LOCAL_URL || "";

export const PageViewEventTrack = () => {
  const selectedLayoutSegments = useSelectedLayoutSegments();
  const pathName = usePathname();
  const paths = pathName?.split("/");
  const prevSegment = useRef("");
  const isTable = selectedLayoutSegments?.includes("table");
  const segment = isTable ? selectedLayoutSegments?.[1] : selectedLayoutSegments?.[0] || paths?.[1]; // for page name
  const { analytics: jitsuAnalytics } = useJitsu();
  const { geoLocationInfo, geoLocationInfoLoaded } = useAnalytics();

  useEffect(() => {
    if (prevSegment.current === segment || !geoLocationInfoLoaded) return;
    const hasSubDomain = (IS_LOCAL_DEV ? LOCAL_URL : window.location.hostname).split(".").length > 2;
    if (segment && !hasSubDomain) {
      jitsuAnalytics.page(startCase(segment), {
        context: {
          location: geoLocationInfo?.location,
          ip: geoLocationInfo?.ip
        }
      });
      prevSegment.current = segment;
    }
  }, [segment, geoLocationInfo, geoLocationInfoLoaded, jitsuAnalytics]);

  return null;
};
